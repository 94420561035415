import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../Component/firebase-config';

const MessageBtn = ({ color }) => {
    const [count, setCount] = useState(128);
    const { t } = useTranslation();
    const [isFromAd, setIsFromAd] = useState(false);
    const [adSource, setAdSource] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const isFromSnapAd = queryParams.get('sc_referrer') === 'snapchat';
        const isFromFbAd = queryParams.get('fbclid') !== null;

        setIsFromAd(isFromSnapAd || isFromFbAd);
        setAdSource(isFromSnapAd ? 'snapchat' : (isFromFbAd ? 'facebook' : ''));

        const interval = setInterval(() => {
            setCount(prevCount => prevCount + (Math.random() > 0.5 ? 1 : 2));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const isAndroid = /Android/i.test(navigator.userAgent);
    const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const handlePlaystoreClick = () => {
        const playstore = 'https://play.google.com/store/apps/details?id=com.lol.android';
        const appstore = 'https://apps.apple.com/us/app/lol-anonymous-funny-card/id6670788272'
    
        if (isFromAd) {
            // Snapchat Pixel tracking for APP_REDIRECT
            if (adSource === 'snapchat' && window.snaptr) {
                window.snaptr('track', 'CUSTOM_EVENT_1', {
                    'event_name': 'APP_REDIRECT',
                    'page_name': 'LOL',
                    'page_url': 'Get own message!',
                });
                console.log('Tracked Snapchat APP_REDIRECT event');
            }
    
            // Facebook Pixel tracking for APP_REDIRECT
            if (adSource === 'facebook' && window.fbq) {
                window.fbq('track', 'APP_REDIRECT', {
                    page_name: 'LOL',
                    page_url: 'Get own message!',
                });
                console.log('Tracked Facebook APP_REDIRECT event');
            }
    
            // Firebase Analytics custom event for app_redirect
            logEvent(analytics, 'app_redirect', {
                event_category: 'engagement',
                event_action: 'playstore_redirect',
                page_name: 'LOL',
                page_url: window.location.href,
                ad_source: adSource
            });
            console.log('Logged Firebase app_redirect event');
        }
    
        // Open Play Store link
        if (isAndroid) {
            window.open(playstore, '_blank');
          } else if (isiOS) {
            window.open(appstore, '_blank');
          } else {
            window.open(playstore, '_blank');
          }
    };

    return (
        <>
            <p className='py-2 m-0' style={{ fontSize: "13px", color: color }}>👇 <span>{count}</span> {t('tap')}</p>
            <Button className='bg-black rounded-pill w-100 border-0 py-2 mb-4 pulse mt-1' onClick={handlePlaystoreClick}>
                <p className='fs-5 text-decoration-none text-white m-0'>{t('msg')}</p>
            </Button>
        </>
    );
};

export default MessageBtn;